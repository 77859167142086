<template>
  <div class="col">
    <strong>Günstige Standardgrößen: </strong>
    <div v-if="alternativeVariants !== null && alternativeVariants.length > 0">
      <ul class="list-group">
        <li class="list-group-item text-center pointer" v-for="(item, index) in alternativeVariants" :key="index" @click="onSelectItem(item.width, item.height)">{{ item.width }}x{{ item.height }}cm<br>
          <span class="text-danger">{{ $filters.currency(Math.ceil(item.discounted)) }} </span>
          <span class="total-price total-price--old mt-1">{{ $filters.currency(Math.ceil(item.total)) }}</span>
        </li>
      </ul>
      <div class="text-center mt-2 mb-2">
        <button class="btn btn-sm btn-primary" @click="showAllStandartDims">
          <span v-if="!btnStatus">Alle Standardgrössen anzeigen</span>
          <span v-if="btnStatus">Alle Standardgrössen verbergen</span>
        </button>
      </div>
      <div v-if="stOptions">
        <ul class="list-group scroller">
          <li class="list-group-item text-center" v-for="(item, index) in stOptions" :key="index" @click="onSelectItem(item.width, item.height)">{{ item.width }}x{{ item.height }}cm<br>
            <span class="text-danger">{{ $filters.currency(Math.ceil(item.discounted)) }} </span>
            <span class="total-price total-price--old mt-1">{{ $filters.currency(Math.ceil(item.total)) }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, watchEffect } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
  props: {
    dimension: {
      default: null
    },
    standartOptions: {
      defualt: null
    }
  },
  setup (props) {
    const store = useStore()
    const dimension = ref(props.dimension)
    const stOptions = ref(props.standartOptions)
    const btnStatus = ref(props.btnStatus)
    const alternativeVariants = computed(() => {
      return store.getters['configurator/alternativeVariants']
    })
    const price = computed(() => {
      return store.getters['configurator/price']
    })
    function onSelectItem (width, height) {
      dimension.value.width.value = Number.parseInt(width)
      dimension.value.height.value = Number.parseInt(height)
      store.dispatch('configurator/setDimension', { type: 'width', value: dimension.value.width.value })
      store.dispatch('configurator/setDimension', { type: 'height', value: dimension.value.height.value })
      store.dispatch('configurator/setAlternative', { height: height, width: width })
    }
    function showAllStandartDims () {
      const step = store.getters['configurator/steps'].find(s => s.model_step)
      if (step && step.options) {
        const option = step.options.find(o => o.selected)
        stOptions.value = option.standart_dim.filter(o => o.price > 0)
      }
      btnStatus.value = !btnStatus.value
      if (!btnStatus.value) {
        stOptions.value = null
      }
    }

    watchEffect(() => {
      if (dimension.value) {
        const h = dimension.value.height.value
        const w = dimension.value.width.value
        store.dispatch('configurator/setAlternative', { height: h, width: w })
      }
    })
    return {
      alternativeVariants,
      price,
      onSelectItem,
      showAllStandartDims,
      stOptions,
      btnStatus
    }
  }
}
</script>
<style scoped>
.scroller {
  overflow-y: scroll;
  height: 220px;
}
.pointer {
  cursor: pointer
}
</style>
